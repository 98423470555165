const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'http://121.41.167.225/api'  // 生产环境
  : 'http://localhost:5001/api';  // 开发环境

console.log('Current environment:', process.env.NODE_ENV);  // 添加环境日志
console.log('Using API URL:', API_BASE_URL);  // 添加 API URL 日志

export default {
  baseURL: API_BASE_URL,
  withCredentials: true
};