<template>
  <transition name="toast">
    <div v-if="show" :class="['toast', type]">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MessageToast',
  props: {
    show: Boolean,
    message: String,
    type: {
      type: String,
      default: 'info',
      validator: value => ['success', 'error', 'info', 'warning'].includes(value)
    }
  }
}
</script>

<style scoped>
.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 24px;
  border-radius: 4px;
  color: white;
  z-index: 10000;
}

.success { background-color: #4caf50; }
.error { background-color: #f44336; }
.info { background-color: #2196f3; }
.warning { background-color: #ff9800; }

.toast-enter-active,
.toast-leave-active {
  transition: all 0.3s ease;
}

.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style> 