<template>
  <div class="register-container">
    <div class="form-card">
      <h1 class="title">用户注册</h1>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="username">用户名：</label>
          <input 
            type="text" 
            id="username" 
            v-model="username" 
            @input="checkUsername"
            required 
            placeholder="请输入登录用的用户名"
          />
          <small class="input-tip" :class="{ 'error-tip': usernameExists }">
            {{ usernameExists ? '该用户名已被使用，请更换' : '用户名将用于登录，建议使用字母、数字的组合' }}
          </small>
        </div>
        <div class="form-group">
          <label for="nickname">昵称：</label>
          <input 
            type="text" 
            id="nickname" 
            v-model="nickname" 
            required 
            placeholder="请输入您想显示的昵称"
          />
          <small class="input-tip">昵称将显示在您的个人主页上</small>
        </div>
        <div class="form-group">
          <label for="email">邮箱：</label>
          <input 
            type="email" 
            id="email" 
            v-model="email" 
            required 
            placeholder="请输入邮箱"
          />
        </div>
        <div class="form-group">
          <label for="password">密码：</label>
          <input 
            type="password" 
            id="password" 
            v-model="password" 
            required 
            placeholder="请输入密码"
          />
        </div>
        <button type="submit" class="submit-btn">注册</button>
      </form>
      <p class="switch-prompt">已有账号？<router-link to="/login">立即登录</router-link></p>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<style scoped>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  background-color: #f5f5f5;
  font-family: "Microsoft YaHei", "微软雅黑", sans-serif;
}

.form-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.form-group {
  margin-bottom: 1.8rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

input:focus {
  outline: none;
  border-color: #4a90e2;
}

.submit-btn {
  width: 100%;
  padding: 0.8rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #357abd;
}

.switch-prompt {
  text-align: center;
  margin-top: 1rem;
  color: #666;
}

.switch-prompt a {
  color: #4a90e2;
  text-decoration: none;
}

.switch-prompt a:hover {
  text-decoration: underline;
}

.error {
  color: #ff4444;
  text-align: center;
  margin-top: 1rem;
}

.input-tip {
  display: block;
  font-size: 0.8rem;
  color: #888;
  margin-top: 0.3rem;
  transition: color 0.3s;
}

.error-tip {
  color: #ff4444 !important;
}
</style>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import debounce from 'lodash/debounce'

export default {
  name: 'RegisterView',
  data() {
    return {
      username: '',
      nickname: '',
      email: '',
      password: '',
      error: null,
      usernameExists: false,
    }
  },
  created() {
    this.debouncedCheckUsername = debounce(this.performUsernameCheck, 500)
  },
  methods: {
    ...mapActions(['register']),
    
    checkUsername() {
      if (this.username && this.username.length >= 3) {
        this.debouncedCheckUsername()
      } else {
        this.usernameExists = false
      }
    },

    async performUsernameCheck() {
      if (!this.username) return;
      
      try {
        const response = await axios.get('/user/check-username', {
          params: {
            username: this.username
          }
        })
        console.log('Username check response:', response.data)
        this.usernameExists = response.data.exists
      } catch (error) {
        console.error('检查用户名时出错:', error)
        if (error.response) {
          console.log('Error status:', error.response.status)
          console.log('Error data:', error.response.data)
        } else if (error.request) {
          console.log('No response received:', error.request)
        } else {
          console.log('Error message:', error.message)
        }
        this.usernameExists = false
      }
    },

    async handleSubmit() {
      if (this.usernameExists) {
        this.error = '请更换一个未被使用的用户名'
        return
      }

      try {
        await this.register({
          username: this.username,
          nickname: this.nickname,
          email: this.email,
          password: this.password,
        })
        this.$router.push('/login')
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          this.error = error.response.data.message
        } else {
          this.error = '注册失败，请重试。'
        }
      }
    },
  },
}
</script>
