<template>
  <div class="user-profile">
    <h1 v-if="user">{{ user.nickname || user.username }}的个人主页</h1>
    <div v-if="loading" class="loading">加载中...</div>
    <div v-else-if="error" class="error">
      {{ error }}
      <button @click="retryLoadUserData">重试</button>
    </div>
    <div v-else-if="user && userProfile">
      <h2>阅读统计</h2>
      <div class="stats-row">
        <div class="stat-item">
          <div class="stat-label">总阅读时间</div>
          <div class="stat-value">{{ formatTime(userProfile.total_reading_time) }}</div>
        </div>
        <div class="stat-divider"></div>
        <div class="stat-item">
          <div class="stat-label">共阅读书籍</div>
          <div class="stat-value">{{ userProfile.reading_progress.length }}本</div>
        </div>
        <div class="stat-divider"></div>
        <div class="stat-item">
          <div class="stat-label">读完</div>
          <div class="stat-value">{{ completedBooks }}本</div>
        </div>
        <div class="stat-divider"></div>
        <div class="stat-item">
          <div class="stat-label">正在读</div>
          <div class="stat-value">{{ inProgressBooks }}本</div>
        </div>
      </div>

      <h2>当前阅读</h2>
      <div v-if="userProfile.reading_progress.length === 0">暂无阅读记录。</div>
      <div v-else>
        <div v-for="book in userProfile.reading_progress" :key="book.book_id" class="book-progress">
          <h3>
            <router-link
              :to="{ name: 'BookDetails', params: { id: book.book_id } }"
              class="book-title-link"
            >
              {{ book.book_title }}
              <span class="link-hint" :title="'点击查看《' + book.book_title + '》的详细信息'">
                <i class="fas fa-info-circle"></i>
              </span>
            </router-link>
          </h3>
          <p>当前章节: {{ book.current_chapter_title || '未开始' }}</p>
          <button
            v-if="!book.is_completed"
            @click="continueReading(book.book_id, book.current_chapter_id)"
            class="continue-reading-btn"
          >
            继续阅读下一章
          </button>
          <p>本书共 {{ book.total_chapters }} 章，已阅读 {{ book.completed_chapters }} 章</p>
          <p>完成度: {{ book.progress.toFixed(2) }}%</p>
          <p>已阅读时间: {{ formatTime(book.total_reading_time) }}</p>
        </div>
      </div>
    </div>
    <div v-else class="error">无法获取用户数据</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserProfile',
  data() {
    return {
      loading: true,
      error: null,
      showReadingHint: true, // 控制提示的显示
    }
  },
  computed: {
    ...mapGetters(['user', 'userProfile']),
    completedBooks() {
      return this.userProfile?.reading_progress.filter(book => book.is_completed).length || 0
    },
    inProgressBooks() {
      return this.userProfile?.reading_progress.filter(book => !book.is_completed).length || 0
    },
  },
  methods: {
    ...mapActions(['fetchUserProfile', 'fetchReadingProgress']),
    formatTime(seconds) {
      if (!seconds || seconds === 0) return '0秒'

      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const remainingSeconds = seconds % 60

      let result = []
      if (hours > 0) result.push(`${hours}小时`)
      if (minutes > 0) result.push(`${minutes}分钟`)
      if (remainingSeconds > 0 || result.length === 0) result.push(`${remainingSeconds}秒`)

      return result.join('')
    },
    async loadUserData() {
      this.loading = true
      this.error = null
      try {
        const userProfileData = await this.fetchUserProfile()
        console.log('用户资料数据:', userProfileData)
      } catch (error) {
        console.error('加载用户数据失败:', error)
        this.error = '加载用户数据失败。请稍后重试。'
      } finally {
        this.loading = false
      }
    },
    retryLoadUserData() {
      this.loadUserData()
    },
    continueReading(bookId, currentChapterId) {
      // 计算下一章的 ID
      const nextChapterId = currentChapterId + 1
      // 跳转到阅读计时页面
      this.$router.push({ name: 'ReadingTimer', params: { bookId, chapterId: nextChapterId } })
    },
    dismissHint() {
      this.showReadingHint = false
      // 可以存储到 localStorage，这样用户下次访问就不会看到提示
      localStorage.setItem('readingHintDismissed', 'true')
    },
  },
  created() {
    // 检查用户是否已经看过提示
    this.showReadingHint = !localStorage.getItem('readingHintDismissed')
    this.loadUserData()
  },
}
</script>

<style scoped>
.user-profile {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.loading,
.error {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}

.error {
  color: red;
}

.book-progress {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
}

h1,
h2,
h3 {
  color: #333;
}

p {
  margin: 10px 0;
}

.continue-reading-btn {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.continue-reading-btn:hover {
  background-color: #45a049;
}

.stats-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stat-item {
  text-align: center;
  flex: 1;
}

.stat-divider {
  width: 1px;
  height: 40px;
  background-color: #ddd;
  margin: 0 15px;
}

.stat-label {
  color: #666;
  font-size: 0.9em;
  margin-bottom: 8px;
}

.stat-value {
  color: #333;
  font-size: 1.5em;
  font-weight: bold;
}

.book-title-link {
  color: #2c3e50;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-right: 25px;
}

.book-title-link:hover {
  color: #42b983;
}

.link-hint {
  display: inline-flex;
  align-items: center;
  color: #42b983;
  margin-left: 8px;
  font-size: 0.9em;
  opacity: 0.8;
  transition: all 0.3s ease;
}

.book-title-link:hover .link-hint {
  opacity: 1;
  transform: scale(1.1);
}

.reading-hint {
  background-color: #e3f2fd;
  color: #1976d2;
  padding: 12px 20px;
  border-radius: 8px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: slideIn 0.5s ease;
  position: relative;
}

.reading-hint i.fa-lightbulb {
  color: #ffc107;
  font-size: 1.2em;
}

.hint-close {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #1976d2;
  cursor: pointer;
  padding: 5px;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.hint-close:hover {
  opacity: 1;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
