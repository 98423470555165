<template>
  <div class="image-uploader">
    <div class="preview" v-if="imageUrl">
      <img :src="getFullImageUrl(imageUrl)" alt="Preview" />
    </div>
    <input type="file" @change="handleFileChange" accept="image/*" ref="fileInput" />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ImageUploader',
  props: {
    category: {
      type: String,
      required: true,
      validator: (value) => ['book_covers', 'icons'].includes(value),
    },
  },
  data() {
    return {
      imageUrl: null,
    }
  },
  methods: {
    async handleFileChange(event) {
      const file = event.target.files[0]
      if (!file) return

      const formData = new FormData()
      formData.append('file', file)

      try {
        const response = await axios.post(`/upload/${this.category}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
          },
          withCredentials: true,
        })

        if (response.data && response.data.url) {
          const fullUrl = `http://localhost:5001${response.data.url}`
          this.imageUrl = fullUrl
          this.$emit('upload-success', { url: fullUrl })
          console.log('Upload successful:', fullUrl)
        } else {
          throw new Error('Invalid response format')
        }
      } catch (error) {
        console.error('Upload failed:', error)
        this.$emit('upload-error', error)
      }
    },
    getFullImageUrl(url) {
      // 如果是完整的 URL，直接返回
      if (url.startsWith('http')) {
        return url
      }
      // 否则，添加后端服务器地址
      return `http://localhost:5001${url}`
    },
  },
}
</script>

<style scoped>
.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.preview {
  width: 200px;
  height: 200px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

input[type='file'] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

input[type='file']:hover {
  background-color: #f5f5f5;
}
</style>
