// store/modules/error.js
export default {
  namespaced: true,
  state: {
    error: null,
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error
    },
    CLEAR_ERROR(state) {
      state.error = null
    },
  },
  actions: {
    setError({ commit }, error) {
      commit('SET_ERROR', error)
    },
    clearError({ commit }) {
      commit('CLEAR_ERROR')
    },
  },
  getters: {
    getError: (state) => state.error,
  },
}
