import { createApp } from 'vue'
import MessageToast from '@/components/MessageToast.vue'

export const message = {
  install(app) {
    const messageInstance = createApp(MessageToast, {
      show: false,
      message: '',
      type: 'info'
    })

    const messageDiv = document.createElement('div')
    document.body.appendChild(messageDiv)
    const messageComponent = messageInstance.mount(messageDiv)

    const showMessage = (message, type = 'info', duration = 3000) => {
      messageComponent.message = message
      messageComponent.type = type
      messageComponent.show = true

      setTimeout(() => {
        messageComponent.show = false
      }, duration)
    }

    app.config.globalProperties.$message = {
      success: (msg) => showMessage(msg, 'success'),
      error: (msg) => showMessage(msg, 'error'),
      info: (msg) => showMessage(msg, 'info'),
      warning: (msg) => showMessage(msg, 'warning')
    }
  }
} 