<template>
  <div class="home">
    <section class="intro">
      <h2>理念简介</h2>
      <p>我们的目标是帮助您提高阅读效率和理解能力。</p>
    </section>
    <section class="popular-books">
      <h2>推荐阅读</h2>
      <div class="books-grid">
        <div v-for="book in popularBooks" :key="book.id" class="book-card">
          <router-link :to="{ name: 'BookDetails', params: { id: book.id } }">
            <div class="book-cover">
              <img
                v-if="book.cover_image"
                :src="book.cover_image"
                :alt="book.title"
                @error="handleImageError"
                @load="handleImageLoad"
              />
              <div v-else class="no-cover">暂无封面</div>
            </div>
            <div class="book-info">
              <h3>{{ book.title }}</h3>
              <p class="author">{{ book.author }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeView',
  computed: {
    ...mapGetters(['popularBooks']),
  },
  methods: {
    ...mapActions(['fetchPopularBooks']),
    handleImageError(e) {
      console.error('Image load error for:', e.target.src)
      e.target.src = '/images/default-cover.png'
    },
    handleImageLoad(e) {
      console.log('Image loaded successfully:', e.target.src)
    },
  },
  async created() {
    await this.fetchPopularBooks()
    console.log('Popular books with full data:', this.popularBooks)
  },
}
</script>

<style scoped>
.books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
}

.book-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
}

.book-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.book-cover {
  height: 280px;
  overflow: hidden;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-cover img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.no-cover {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}

.book-info {
  padding: 15px;
}

.book-info h3 {
  margin: 0;
  font-size: 1.1em;
  color: #333;
}

.author {
  color: #666;
  margin: 5px 0 0;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .books-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 15px;
    padding: 15px;
  }

  .book-cover {
    height: 220px;
  }
}
</style>
