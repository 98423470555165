import { createStore } from 'vuex'
import axios from 'axios'
import apiConfig from '../api/config'
import errorModule from './modules/error'
// 导入其他模块
// import authModule from './modules/auth'
// import userModule from './modules/user'

const api = axios.create({
  baseURL: apiConfig.baseURL,
  withCredentials: true
})

export default createStore({
  state: {
    user: null,
    userProfile: null,
    loading: false,
    error: null,
    readingProgress: [],
    popularBooks: [],
    chapterQuestions: {},
    currentBook: null,
    isAuthenticated: false,
    isAdmin: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
      state.isAuthenticated = !!user
      state.isAdmin = user ? user.is_admin : false
    },
    SET_USER_PROFILE(state, profile) {
      state.userProfile = profile
    },
    SET_LOADING(state, isLoading) {
      state.loading = isLoading
    },
    SET_READING_PROGRESS(state, progress) {
      state.readingProgress = progress
    },
    SET_POPULAR_BOOKS(state, books) {
      state.popularBooks = books
    },
    SET_CHAPTER_QUESTIONS(state, { chapterId, questions }) {
      state.chapterQuestions = {
        ...state.chapterQuestions,
        [chapterId]: questions,
      }
    },
    SET_CURRENT_BOOK(state, book) {
      state.currentBook = book
    },
    SET_ERROR(state, errorMessage) {
      state.error = errorMessage
    },
    CLEAR_ERROR(state) {
      state.error = null
    },
    CLEAR_USER(state) {
      state.user = null
      state.isAuthenticated = false
    },
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    },
  },
  actions: {
    async login({ commit, dispatch }, { username, password }) {
      try {
        const response = await api.post('/auth/login', { username, password })
        console.log('Login response:', response.data)
        commit('SET_USER', response.data.user)
        localStorage.setItem('isAuthenticated', 'true')
        // 添加这行来立即获取用户信息
        await dispatch('fetchUser')
        return response.data
      } catch (error) {
        console.error('Login error:', error.response ? error.response.data : error)
        commit('SET_ERROR', error.response?.data?.message || 'Login failed')
        throw error
      }
    },
    async register({ commit }, userData) {
      try {
        const response = await api.post('/auth/register', userData)
        if (response.data.user) {
          commit('SET_USER', response.data.user)
          return response.data
        }
      } catch (error) {
        console.error('Registration failed:', error)
        throw error
      }
    },
    async logout({ commit }) {
      try {
        await api.get('/auth/logout')
      } catch (error) {
        console.error('Logout failed:', error)
      } finally {
        commit('CLEAR_USER')
        commit('SET_AUTHENTICATED', false)
        localStorage.removeItem('isAuthenticated')
      }
    },
    async fetchUser({ commit }) {
      if (!localStorage.getItem('isAuthenticated')) {
        commit('CLEAR_USER')
        commit('SET_AUTHENTICATED', false)
        return
      }
      try {
        const response = await api.get('/auth/user')
        if (response.data && response.data.id) {
          commit('SET_USER', response.data)
          commit('SET_AUTHENTICATED', true)
        } else {
          throw new Error('Invalid user data')
        }
      } catch (error) {
        console.error('Fetch user error:', error)
        commit('CLEAR_USER')
        commit('SET_AUTHENTICATED', false)
        localStorage.removeItem('isAuthenticated')
      }
    },
    async fetchUserProfile({ commit }) {
      commit('SET_LOADING', true)
      commit('SET_ERROR', null)
      try {
        const response = await api.get('/user/profile')
        console.log('Fetched user profile:', response.data)
        
        // 修改这里以匹配后端返回的数据结构
        const { user, reading_progress, total_reading_time } = response.data
        
        commit('SET_USER', user)
        commit('SET_USER_PROFILE', {
          ...user,
          reading_progress,
          total_reading_time
        })
        
        return response.data
      } catch (error) {
        console.error('Failed to fetch user profile:', error)
        commit('SET_ERROR', '获取用户资料失败')
        throw new Error('获取用户资料失败')
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchReadingProgress({ commit }) {
      try {
        const response = await api.get('/reading/progress')
        commit('SET_READING_PROGRESS', response.data)
        return response.data
      } catch (error) {
        console.error('Failed to fetch reading progress:', error)
        throw error
      }
    },
    async fetchBookProgress(_, bookId) {
      try {
        const response = await api.get(`/reading/progress/${bookId}`)
        return response.data
      } catch (error) {
        console.error(`Failed to fetch progress for book ${bookId}:`, error)
        throw error
      }
    },
    async updateReadingProgress({ commit }, progressData) {
      try {
        console.log('Sending progress data:', progressData) // 添加这行来记录发送的数据
        const response = await api.post('/reading/update_progress', progressData)
        console.log('Update reading progress response:', response.data)
        // commit('SET_READING_PROGRESS', response.data.progress);
        return response.data
      } catch (error) {
        console.error('更新阅读进度失败:', error.response ? error.response.data : error)
        commit('SET_ERROR', error.response?.data?.error || '更新阅读进度失败')
        throw error
      }
    },
    async fetchPopularBooks({ commit }) {
      console.log('Attempting to fetch popular books...')
      try {
        const response = await api.get('/books/popular')
        console.log('Popular books raw data:', response.data)

        // 处理图片 URL - 不需要再次添加前缀，因为后端已经返回完整URL
        const booksWithFullUrls = response.data.map((book) => {
          console.log('Processing book:', book)
          console.log('Original cover_image:', book.cover_image)

          return {
            ...book,
            // 直接使用后端返回的URL，不需要再处理
            cover_image: book.cover_image,
          }
        })

        console.log('Books with processed URLs:', booksWithFullUrls)
        commit('SET_POPULAR_BOOKS', booksWithFullUrls)
        return booksWithFullUrls
      } catch (error) {
        console.error('Error fetching popular books:', error)
        throw error
      }
    },
    async fetchBookDetails(_, bookId) {
      try {
        const response = await api.get(`/books/${bookId}/details`)
        return response.data
      } catch (error) {
        console.error('Failed to fetch book details:', error)
        throw error
      }
    },
    async fetchChapterQuestions({ commit }, chapterId) {
      try {
        const response = await api.get(`/chapters/${chapterId}/questions`)
        commit('SET_CHAPTER_QUESTIONS', { chapterId, questions: response.data })
        return response.data
      } catch (error) {
        console.error('Failed to fetch chapter questions:', error)
        throw error
      }
    },
    async fetchBook({ commit }, bookId) {
      try {
        const response = await api.get(`/books/${bookId}`)
        commit('SET_CURRENT_BOOK', response.data)
        return response.data
      } catch (error) {
        console.error('Failed to fetch book:', error)
        throw error
      }
    },
    async submitChapterQuiz(_, { bookId, chapterId, answers, questionIds }) {
      try {
        const response = await api.post(`/books/${bookId}/chapters/${chapterId}/quiz`, {
          answers,
          questionIds,
        })

        if (!response.data.correctAnswers || !response.data.correctOptions) {
          console.error('Invalid response format:', response.data)
          throw new Error('Invalid response format from server')
        }

        return response.data
      } catch (error) {
        console.error('Failed to submit quiz:', error)
        throw error
      }
    },
    async checkBackendConnection({ commit }) {
      try {
        const response = await api.get('/test')
        console.log('Backend response:', response.data)
        if (response.data && response.data.message) {
          commit('CLEAR_ERROR')
          return response.data
        } else {
          throw new Error('Unexpected response from server')
        }
      } catch (error) {
        console.error('Connection error:', error)
        commit('SET_ERROR', 'Backend connection failed: ' + error.message)
        throw error
      }
    },
    clearError({ commit }) {
      commit('SET_ERROR', null)
    },
    setError({ commit }, error) {
      commit('SET_ERROR', error)
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    readingProgress: (state) => state.readingProgress,
    popularBooks: (state) => state.popularBooks,
    chapterQuestions: (state) => (chapterId) => state.chapterQuestions[chapterId] || [],
    currentBook: (state) => state.currentBook,
    userProfile: (state) => state.userProfile,
    isLoading: (state) => state.loading,
    error: (state) => state.error,
    hasError: (state) => !!state.error,
    isAdmin: (state) => state.isAdmin,
  },
  modules: {
    error: errorModule,
    // 其他模块
    // auth: authModule,
    // user: userModule
  },
})
