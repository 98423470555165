<template>
  <div class="login-container">
    <div class="form-card">
      <h1 class="title">用户登录</h1>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="username">用户名：</label>
          <input 
            type="text" 
            id="username" 
            v-model="username" 
            required 
            placeholder="请输入用户名"
          />
        </div>
        <div class="form-group">
          <label for="password">密码：</label>
          <input 
            type="password" 
            id="password" 
            v-model="password" 
            required 
            placeholder="请输入密码"
          />
        </div>
        <button type="submit" :disabled="isLoading" class="submit-btn">
          {{ isLoading ? '登录中...' : '登录' }}
        </button>
      </form>
      <p class="switch-prompt">还没有账号？<router-link to="/register">立即注册</router-link></p>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LoginView',
  data() {
    return {
      username: '',
      password: '',
      error: null,
      isLoading: false,
    }
  },
  methods: {
    ...mapActions(['login', 'fetchUser']),
    async handleSubmit() {
      this.isLoading = true
      this.error = null
      try {
        await this.login({
          username: this.username,
          password: this.password,
        })
        await this.fetchUser() // 确保用户信息被更新
        this.$router.replace('/') // 使用 replace 而不是 push
      } catch (error) {
        console.error('Login error:', error)
        this.error = error.response?.data?.message || 'Invalid username or password'
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  background-color: #f5f5f5;
  font-family: "Microsoft YaHei", "微软雅黑", sans-serif;
}

.form-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #666;
}

input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

input:focus {
  outline: none;
  border-color: #4a90e2;
}

.submit-btn {
  width: 100%;
  padding: 0.8rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover:not(:disabled) {
  background-color: #357abd;
}

.submit-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.switch-prompt {
  text-align: center;
  margin-top: 1rem;
  color: #666;
}

.switch-prompt a {
  color: #4a90e2;
  text-decoration: none;
}

.switch-prompt a:hover {
  text-decoration: underline;
}

.error {
  color: #ff4444;
  text-align: center;
  margin-top: 1rem;
}
</style>
