<template>
  <div class="settings">
    <h1>设置</h1>
    <div v-if="loading" class="loading">加载中...</div>
    <div v-else-if="error" class="error">
      {{ error }}
      <button @click="retryLoadUserData">重试</button>
    </div>
    <div v-else-if="user">
      <h2>个人信息</h2>
      <p><strong>姓名:</strong> {{ user.full_name }}</p>
      <p><strong>邮箱:</strong> {{ user.email }}</p>
      <p><strong>个人简介:</strong> {{ user.bio || '暂无简介' }}</p>
      <p><strong>所在地:</strong> {{ user.location || '未指定' }}</p>
      <!-- 这里可以添加编辑个人信息的功能 -->
    </div>
    <div v-else class="error">无法获取用户数据</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SettingsView',
  data() {
    return {
      loading: true,
      error: null,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    ...mapActions(['fetchUser']),
    async loadUserData() {
      this.loading = true
      this.error = null
      try {
        await this.fetchUser()
      } catch (error) {
        console.error('加载用户数据失败:', error)
        this.error = '加载用户数据失败。请稍后重试。'
      } finally {
        this.loading = false
      }
    },
    retryLoadUserData() {
      this.loadUserData()
    },
  },
  created() {
    this.loadUserData()
  },
}
</script>

<style scoped>
.settings {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.loading,
.error {
  text-align: center;
  font-size: 1.2em;
  margin-top: 20px;
}

.error {
  color: red;
}

h1,
h2 {
  color: #333;
}

p {
  margin: 10px 0;
}
</style>
