<template>
  <nav class="navbar">
    <div class="nav-left">
      <div v-if="isAdmin" class="admin-menu">
        <router-link to="/admin/books/manage" class="nav-link admin-link">书籍管理</router-link>
      </div>
    </div>

    <div class="nav-center">
      <router-link to="/" class="brand-link">
        <img src="/images/bookworm1.png" alt="书虫阅读" class="nav-logo" />
        <span class="brand-name">书虫阅读</span>
      </router-link>
    </div>

    <div class="nav-right">
      <template v-if="isAuthenticated">
        <router-link to="/profile" class="nav-link">个人主页</router-link>
        <div class="dropdown">
          <button class="dropdown-toggle" @click="toggleDropdown">
            {{ username }}
            <span class="dropdown-arrow" :class="{ 'arrow-up': showDropdown }">▼</span>
          </button>
          <div class="dropdown-menu" v-show="showDropdown">
            <router-link to="/settings" class="dropdown-item">设置</router-link>
            <a @click.prevent="handleLogout" class="dropdown-item">退出</a>
          </div>
        </div>
      </template>
      <template v-else>
        <router-link to="/login" class="nav-link">登录</router-link>
        <router-link to="/register" class="nav-link">注册</router-link>
      </template>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NavBar',
  data() {
    return {
      showDropdown: false,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'user', 'isAdmin']),
    username() {
      return this.user ? this.user.username : ''
    },
  },
  methods: {
    ...mapActions(['logout']),
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    async handleLogout() {
      try {
        await this.logout()
        this.showDropdown = false
        this.$router.push('/login')
      } catch (error) {
        console.error('Logout failed:', error)
      }
    },
  },
}
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 64px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-left,
.nav-right {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-right {
  justify-content: flex-end;
}

.nav-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand-link {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.brand-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.nav-logo {
  height: 36px;
  width: auto;
}

.brand-name {
  font-size: 1.4em;
  font-weight: 600;
  color: #2c3e50;
}

.nav-link {
  text-decoration: none;
  color: #2c3e50;
  padding: 8px 16px;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.nav-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.admin-link {
  color: #fff;
  background-color: #4caf50;
}

.admin-link:hover {
  background-color: #45a049;
}

.dropdown {
  position: relative;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 500;
  color: #2c3e50;
  border-radius: 6px;
}

.dropdown-toggle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dropdown-arrow {
  transition: transform 0.2s;
}

.arrow-up {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 160px;
  padding: 8px 0;
}

.dropdown-item {
  display: block;
  padding: 8px 16px;
  color: #2c3e50;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}
</style>
