<template>
  <div class="book-management">
    <h2>{{ isEditing ? '编辑书籍' : '添加新书' }}</h2>

    <!-- 添加搜索和筛选区域 -->
    <div class="search-filter">
      <div class="search-box">
        <input
          v-model="searchQuery"
          type="text"
          placeholder="搜索书籍标题或作者..."
          @input="filterBooks"
        />
      </div>
      <div class="sort-options">
        <select v-model="sortBy" @change="filterBooks">
          <option value="title">按标题排序</option>
          <option value="author">按作者排序</option>
          <option value="newest">最新添加</option>
        </select>
      </div>
    </div>

    <!-- 添加/编辑书籍表单 -->
    <div class="book-form">
      <div class="form-group">
        <label>书籍标题</label>
        <input v-model="book.title" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>作者</label>
        <input v-model="book.author" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label>描述</label>
        <textarea v-model="book.description" class="form-control"></textarea>
      </div>
      <div class="form-group">
        <label>书籍封面</label>
        <ImageUploader
          category="book_covers"
          @upload-success="handleCoverUpload"
          @upload-error="handleUploadError"
        />
        <img
          v-if="book.cover_image"
          :src="book.cover_image"
          alt="Book cover preview"
          class="cover-preview"
        />
      </div>
      <div class="form-actions">
        <button @click="saveBook" class="save-btn">
          {{ isEditing ? '保存修改' : '保存书籍' }}
        </button>
        <button v-if="isEditing" @click="cancelEdit" class="cancel-btn">取消编辑</button>
      </div>
    </div>

    <!-- 显示保存结果 -->
    <div v-if="saveMessage" :class="['message', saveMessage.type]">
      {{ saveMessage.text }}
    </div>

    <!-- 书籍列表 -->
    <div class="book-list" v-if="filteredBooks.length">
      <h3>现有书籍 ({{ filteredBooks.length }} 本)</h3>
      <div class="books-grid">
        <div v-for="book in filteredBooks" :key="book.id" class="book-card">
          <div class="book-cover">
            <img
              v-if="book.cover_image"
              :src="getFullImageUrl(book.cover_image)"
              alt="Book cover"
              @error="handleImageError"
            />
            <div v-else class="no-cover">暂无封面</div>
          </div>
          <div class="book-info">
            <h4>{{ book.title }}</h4>
            <p class="author">作者：{{ book.author }}</p>
            <p class="description">{{ truncateDescription(book.description) }}</p>
          </div>
          <div class="book-actions">
            <button @click="editBook(book)" class="edit-btn">编辑</button>
            <button @click="deleteBook(book.id)" class="delete-btn">删除</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-results">没有找到匹配的书籍</div>
  </div>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'
import axios from 'axios'

export default {
  name: 'BookManagement',
  components: {
    ImageUploader,
  },
  data() {
    return {
      book: {
        title: '',
        author: '',
        description: '',
        cover_image: '',
      },
      books: [],
      saveMessage: null,
      isEditing: false, // 添加编辑状态标记
      editingBookId: null, // 添加正在编辑的书籍ID
      searchQuery: '',
      sortBy: 'title',
      filteredBooks: [],
    }
  },
  methods: {
    handleCoverUpload(data) {
      console.log('Upload response data:', data)
      this.book.cover_image = data.url
      console.log('Set book cover image to:', this.book.cover_image)
    },
    handleUploadError(error) {
      console.error('Upload failed:', error)
      this.saveMessage = {
        type: 'error',
        text: '图片上传失败：' + error.message,
      }
    },
    async saveBook() {
      try {
        console.log('Saving book with data:', this.book)
        let response
        if (this.isEditing) {
          // 更新现有书籍
          response = await axios.put(`/books/${this.editingBookId}`, this.book)
          console.log('Book updated:', response.data)
          this.saveMessage = {
            type: 'success',
            text: '书籍更新成功！',
          }
        } else {
          // 创建新书籍
          response = await axios.post('/books', this.book)
          console.log('Book created:', response.data)
          this.saveMessage = {
            type: 'success',
            text: '书籍保存成功！',
          }
        }

        // 重置表单
        this.resetForm()
        // 重新加载书籍列表
        this.loadBooks()
      } catch (error) {
        console.error('Error details:', error.response?.data)
        console.error('Error saving book:', error)
        this.saveMessage = {
          type: 'error',
          text: '保存失败：' + (error.response?.data?.error || error.message),
        }
      }
    },
    async loadBooks() {
      try {
        const response = await axios.get('/books')
        console.log('Loaded books:', response.data.books)
        this.books = response.data.books
        this.filterBooks()
      } catch (error) {
        console.error('Error loading books:', error)
        this.saveMessage = {
          type: 'error',
          text: '加载书籍列表失败',
        }
      }
    },
    editBook(book) {
      // 设置编辑状态
      this.isEditing = true
      this.editingBookId = book.id
      // 复制书籍数据到表单
      this.book = { ...book }
      // 滚动到表单顶部
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    cancelEdit() {
      // 取消编辑，重置表单
      this.resetForm()
    },
    resetForm() {
      // 重置表单和编辑状态
      this.book = {
        title: '',
        author: '',
        description: '',
        cover_image: '',
      }
      this.isEditing = false
      this.editingBookId = null
    },
    async deleteBook(bookId) {
      if (!confirm('确定删除这本书吗？')) return

      try {
        await axios.delete(`/books/${bookId}`)
        this.saveMessage = {
          type: 'success',
          text: '书籍删除成功！',
        }
        this.loadBooks()
      } catch (error) {
        console.error('Error deleting book:', error)
        this.saveMessage = {
          type: 'error',
          text: '删除失败：' + (error.response?.data?.error || error.message),
        }
      }
    },
    truncateDescription(desc) {
      if (!desc) return ''
      return desc.length > 100 ? desc.substring(0, 100) + '...' : desc
    },
    handleImageError(e) {
      console.error('Image load error:', e)
      e.target.src = '/images/default-cover.png'
    },
    filterBooks() {
      let result = [...this.books]

      // 搜索过滤
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase()
        result = result.filter(
          (book) =>
            book.title.toLowerCase().includes(query) || book.author.toLowerCase().includes(query)
        )
      }

      // 排序
      result.sort((a, b) => {
        switch (this.sortBy) {
          case 'title':
            return a.title.localeCompare(b.title)
          case 'author':
            return a.author.localeCompare(b.author)
          case 'newest':
            return new Date(b.created_at) - new Date(a.created_at)
          default:
            return 0
        }
      })

      this.filteredBooks = result
    },
    getFullImageUrl(url) {
      console.log('Processing URL:', url)
      if (!url) return ''
      if (url.startsWith('http')) return url
      return `http://localhost:5001${url}`
    },
  },
  watch: {
    books: {
      handler() {
        this.filterBooks()
      },
      immediate: true,
    },
  },
  created() {
    this.loadBooks()
  },
}
</script>

<style scoped>
.book-management {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.book-form {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea.form-control {
  height: 100px;
}

.cover-preview {
  max-width: 200px;
  margin-top: 10px;
  border: 1px solid #ddd;
}

.save-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #45a049;
}

.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
}

.success {
  background-color: #dff0d8;
  color: #3c763d;
  border: 1px solid #d6e9c6;
}

.error {
  background-color: #f2dede;
  color: #a94442;
  border: 1px solid #ebccd1;
}

.book-list {
  margin-top: 30px;
}

.book-item {
  display: flex;
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 15px;
  border-radius: 4px;
}

.book-cover {
  height: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-cover img {
  height: 100%;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s;
}

.book-card:hover .book-cover img {
  transform: scale(1.05);
}

.no-cover {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  color: #999;
  font-size: 1.1em;
}

.book-info {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.book-info h4 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.author {
  color: #666;
  font-size: 0.95em;
  margin: 5px 0;
}

.description {
  color: #777;
  font-size: 0.9em;
  line-height: 1.4;
  margin-bottom: 10px;
}

.book-actions {
  padding: 15px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  border-top: 1px solid #eee;
}

.edit-btn,
.delete-btn {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-btn {
  background-color: #337ab7;
  color: white;
}

.delete-btn {
  background-color: #d9534f;
  color: white;
}

.edit-btn:hover {
  background-color: #286090;
}

.delete-btn:hover {
  background-color: #c9302c;
}

.form-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.cancel-btn {
  background-color: #6c757d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #5a6268;
}

.books-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

.book-card {
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  background: white;
  display: flex;
  flex-direction: column;
}

.book-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.book-cover {
  height: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-cover img {
  height: 100%;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s;
}

.book-card:hover .book-cover img {
  transform: scale(1.05);
}

.no-cover {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  color: #999;
  font-size: 1.1em;
}

.book-info {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.book-info h4 {
  margin: 0;
  font-size: 1.2em;
  color: #333;
}

.author {
  color: #666;
  font-size: 0.95em;
  margin: 5px 0;
}

.description {
  color: #777;
  font-size: 0.9em;
  line-height: 1.4;
  margin-bottom: 10px;
}

.book-actions {
  padding: 15px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  border-top: 1px solid #eee;
}

.edit-btn,
.delete-btn {
  padding: 8px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.2s;
}

.search-filter {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.search-box {
  flex: 1;
}

.search-box input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.sort-options select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.no-results {
  text-align: center;
  padding: 20px;
  color: #666;
}
</style>
