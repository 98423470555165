<template>
  <div id="app">
    <NavBar />
    <main class="main-content">
      <router-view />
    </main>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
  },
  created() {
    // 设置页面标题和副标题（slogan）
    document.title = '书虫阅读 - 每一秒阅读都算数'

    // 设置网站图标（favicon）
    const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link')
    favicon.type = 'image/png'
    favicon.rel = 'shortcut icon'
    favicon.href = '/images/bookworm1.png' // 确保这个路径指向您的logo图片
    document.getElementsByTagName('head')[0].appendChild(favicon)
  },
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.main-content {
  margin-top: 64px;
  min-height: calc(100vh - 64px);
}
</style>
