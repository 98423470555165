import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import UserProfile from '@/views/UserProfile.vue'
import RegisterView from '../views/Register.vue'
import SettingsView from '../views/SettingsView.vue'
import BookManagement from '@/views/BookManagement.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  {
    path: '/book/:id',
    name: 'BookDetails',
    component: () => import(/* webpackChunkName: "book-details" */ '../views/BookDetails.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/reading/:bookId/:chapterId',
    name: 'ReadingTimer',
    component: () => import(/* webpackChunkName: "reading-timer" */ '../views/ReadingTimer.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/quiz/:bookId/:chapterId',
    name: 'Quiz',
    component: () => import(/* webpackChunkName: "quiz" */ '../views/QuizView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/books/manage',
    name: 'BookManagement',
    component: BookManagement,
    meta: { requiresAdmin: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// 添加导航守卫以保护需要认证的路由
router.beforeEach(async (to, from, next) => {
  // 检查是否需要认证
  if (to.matched.some((record) => record.meta.requiresAuth || record.meta.requiresAdmin)) {
    if (!store.getters.isAuthenticated) {
      try {
        await store.dispatch('fetchUser')
      } catch (error) {
        console.error('Error fetching user:', error)
      }
    }

    if (!store.getters.isAuthenticated) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath },
      })
      return
    }

    // 检查管理员权限
    if (to.matched.some((record) => record.meta.requiresAdmin)) {
      if (!store.getters.isAdmin) {
        next({ name: 'Home' })
        return
      }
    }

    next()
  } else {
    next()
  }
})

export default router
